// workaround pro automatickou vysku flowbite carouselu
const carouselSelector = '[data-courousel-autoheightfix]'

const activeItemClass = 'jsCarouselActiveItem'

const getElementRealChildrens = (elm: HTMLElement) => Array.from(elm.childNodes).filter(node => node.nodeType == 1) as HTMLElement[]

const calculateSumHeightOfChildrens = (elm: HTMLElement) => {
    let height = 0
    getElementRealChildrens(elm).map(children => {
        const ch = children as HTMLElement
        height = height + ch.offsetHeight
    })
    return height
}

const setActiveItemHeight = (elm: HTMLElement) => {
    elm.style.height = 'auto'
    const height = calculateSumHeightOfChildrens(elm)
    elm.style.height = height + 'px'
    const parent = elm?.parentElement
    if (!!parent) {
        parent.style.height = (height + 20) + 'px'
    }
}

import { Carousel } from 'flowbite';

// autostart
document.addEventListener("DOMContentLoaded", function () {
    Array.from(document.querySelectorAll(carouselSelector)).map(carousel => {

        // carousel items
        const itemsElms = Array.from(carousel.querySelectorAll('[data-carousel-item]'))
        if (itemsElms.length == 0) return;

        // indicators
        const indicatorsElms = Array.from(carousel.querySelectorAll('[data-carousel-slide-to]'))

        // priprava items pro definici carouselu
        const carouselItems = itemsElms.map((itemElm, i) => {
            return {position: i, el: itemElm as HTMLElement}
        })

        // definice carouselu
        const carouselOptions = {
            defaultPosition: 0,
            interval: 3000,
            indicators: {
                activeClasses: 'bg-white dark:bg-gray-800',
                inactiveClasses: 'bg-white/50 dark:bg-gray-800/50 hover:bg-white dark:hover:bg-gray-800',
                items: indicatorsElms.map((indicatorItem, i) => { return {position: i, el: indicatorItem as HTMLElement} }),
            },
            onChange: (_carousel) => {

                // aktivni item
                const activeItem = _carousel._activeItem.el

                // nastaveni aktivni classy
                carouselItems.map(item => { item.el.classList.remove(activeItemClass) })
                activeItem.classList.add(activeItemClass)

                // nastaveni vysky
                setActiveItemHeight(activeItem)

                // onload na obrazky v aktivnim itemu
                const activeItemImages = activeItem.querySelectorAll('img')
                !!activeItemImages && Array.from(activeItemImages).map(img => {
                    const loadedImg = new Image()
                    loadedImg.src = (img as HTMLImageElement).src
                    loadedImg.addEventListener('load', () => { setActiveItemHeight(activeItem) })
                })

            },
        }

        // event pro sipku dopredu
        const updatedCarousel = new Carousel(carouselItems, carouselOptions);
        Array.from(carousel.querySelectorAll('[data-carousel-prev]')).map(item => {
            item.addEventListener('click', (e) => {
                e.preventDefault();
                updatedCarousel.prev()
            })
        })

        // event pro sipku dozadu
        Array.from(carousel.querySelectorAll('[data-carousel-next]')).map(item => {
            item.addEventListener('click', (e) => {
                e.preventDefault();
                updatedCarousel.next()
            })
        })
        
    })

    // onResize
    window.addEventListener('resize', () => {
        Array.from(document.querySelectorAll(carouselSelector)).map(carousel => {
            const activeItem = carousel.querySelector('.' + activeItemClass)
            setActiveItemHeight(activeItem as HTMLElement)
        })
    })
})
